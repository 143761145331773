.Home::-webkit-scrollbar {
  width: 5px;
  height: 100px;
}

.Home::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.Home::-webkit-scrollbar-thumb:hover {
  background: #555;
}
